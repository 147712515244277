import React, { useCallback, useContext, useEffect, useState } from "react";

import {
  CardExpandContainer,
  CardExpandContainerHeader,
  CardExpandContainerHeaderIcon,
  CardExpandContainerHeaderContent,
  CardExpandTitle,
  CardExpandText,
  CardExpandContainerBody,
  CardExpandContainerActions,
  CardExpandContainerAction,
  CardChatBallon,
  CardChatBallonText,
  CardChatBallonBottom,
  CardChatBallonBottomItem,
  CardChatBallonBottomItemIcon,
  CardChatBallonBottomItemText,
  ModalContainerHeaderButtonIcon,
  ModalContainerHeaderButton,
  CardExpandContainerHeaderIconDecoration,
  ContainerSendMessageHeaderButtonIcon,
  CardChatDate,
  CardChatDateIcon,
  CardChatDateText,
} from "./styled";

import Button from "components/Form/Button";
import Input from "components/Form/Input";
import { Create, Read } from "services/chats";
import { CoreContext } from "context/CoreContext";
import { exposeStrapiError, normalizeStrapiList } from "utils";
import moment from "moment/moment";
import { Load } from "ui/styled";

export default function CardChatSupport({ closeable, item }) {
  const { user } = useContext(CoreContext);

  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [senting, setSenting] = useState(false);

  const parseMessage = useCallback(
    (item) => {
      const momentDate = moment(item?.createdAt);
      const date = momentDate?.format("L");

      return {
        ...item,
        hourAndMinutes: momentDate?.format("LT"),
        date,
        me: item?.sent?.data?.id === user?.id,
      };
    },
    [user]
  );

  const init = useCallback(async () => {
    setLoading(true);
    setMessage("");
    const result = await Read(item?.id);
    if (result && !exposeStrapiError(result)) {
      const normalResult = normalizeStrapiList(result);
      const sortedChat = normalResult?.sort(
        (a, b) =>
          new Date(a.createdAt)?.getTime() - new Date(b.createdAt)?.getTime()
      );
      const messages = sortedChat?.map(parseMessage);

      const messagesSeparatedByDate = messages.reduce((acc, att) => {
        const { date } = att;

        if (acc[date]) {
          acc[date] = [...acc[date], att];

          return acc;
        }

        acc[date] = [att];

        return acc;
      }, {});

      setChats(messagesSeparatedByDate);
    }
    setLoading(false);
  }, [item, parseMessage]);

  const action = async () => {
    setSenting(true);
    const result = await Create({
      data: {
        sent: user?.id,
        campaign: item.id,
        message,
      },
    });
    if (result?.data?.id) {
      init();
    }
    setSenting(false);
  };

  useEffect(() => {
    init();
  }, [init]);

  return (
    <>
      <CardExpandContainer closeable={closeable}>
        <CardExpandContainerHeader>
          <CardExpandContainerHeaderIcon>
            <CardExpandContainerHeaderIconDecoration />
          </CardExpandContainerHeaderIcon>
          <CardExpandContainerHeaderContent>
            <CardExpandTitle>Atendimento NiceHouse</CardExpandTitle>
            <CardExpandText>Online</CardExpandText>
          </CardExpandContainerHeaderContent>

          {closeable ? (
            <ModalContainerHeaderButton>
              <Button outline nospace onClick={closeable}>
                <ModalContainerHeaderButtonIcon icon={"close"} />
              </Button>
            </ModalContainerHeaderButton>
          ) : null}
        </CardExpandContainerHeader>
        <CardExpandContainerBody>
          {loading ? <Load colored /> : null}
          {Object.entries(chats).map(([date, messages], key) => {
            return (
              <>
                <CardChatDate>
                  <CardChatDateIcon />
                  <CardChatDateText>
                    {date === moment().format("L") ? "Hoje" : date}
                  </CardChatDateText>
                </CardChatDate>{" "}
                {/* <CardChatSpacer /> */}
                {messages.map((message) => (
                  <CardChatBallon me={message.me} key={key}>
                    <CardChatBallonText>{message?.message}</CardChatBallonText>
                    <CardChatBallonBottom>
                      <CardChatBallonBottomItem>
                        <CardChatBallonBottomItemIcon />
                        <CardChatBallonBottomItemText>
                          {message?.hourAndMinutes}
                        </CardChatBallonBottomItemText>
                      </CardChatBallonBottomItem>
                    </CardChatBallonBottom>
                  </CardChatBallon>
                ))}
              </>
            );
          })}
        </CardExpandContainerBody>
        <CardExpandContainerActions>
          <Input
            placeholder="Digite sua mensagem"
            value={message}
            onChange={(e) => setMessage(e?.target?.value)}
          />
          <CardExpandContainerAction>
            <Button
              nospace
              primary
              outline
              loading={senting}
              onClick={action}
              marginRight={"12px"}
            >
              <ContainerSendMessageHeaderButtonIcon icon={"arrow"} />
              ENVIAR
            </Button>
          </CardExpandContainerAction>
        </CardExpandContainerActions>
      </CardExpandContainer>
    </>
  );
}
